.bootstrap-datetimepicker-widget {
  .table th, .table td {
    border: 0;
  }

  table th,
  table td,
  table td.day {
    height: 36px;
    line-height: 36px;
    width: 36px;
  }

  table th {
    font-weight: $headings-font-weight;
  }
}
