.detail-view {
  font-size: 96%;

  td {
    word-break: break-all;
  }

  td img {
    max-width: 100%;
  }
}
